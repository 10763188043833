.user-outlet-main-content {
    display: flex;
    width: 100%;
}

.user-outlet-main-content .user-sidebar-outlet-content {
    max-width: 238px;
    width: 238px;
    flex: 0 0 238px;
}

.user-outlet-main-content  .user-outlet-right-content {
    flex: 1;
    width: calc(100% - 238px);
}

.user-content-inner-data {
    background: #0C0E1D;
    height: 100vh;
    padding-top: 92px;
}

/* .user-content-inner-data .user-inner-content-scroll {
    height: calc(100vh -  84px);
    overflow: auto;
} */

.pd-inner-comman-user {
    padding: 20px 45px 20px 21px;
    /* padding: 30px 45px 50px 21px; */
    height: calc(100vh - 143px);
    overflow: auto;
    margin-top: 1px;
}

/* .without-chat-pd{
    padding: 20px 45px 20px 21px;
}

.left-dashboard-main-user{
    padding: 20px 15px 20px 21px;
} */

