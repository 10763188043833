/* .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 216px);
}

.loader {
  border: 8px solid #2e60eb;
  border-radius: 50%;
  border-top: 8px solid #ffffffde;
  border-bottom: 8px solid #ffffffde;
  width: 70px;
  height: 70px;
    animation: spin 2s linear infinite;
}
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */

  
.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle;
}

.loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #2e60eb;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 2s cubic-bezier(.42, .61, .58, .41) infinite;
  animation: loader-1-outter 2s cubic-bezier(.42, .61, .58, .41) infinite;
}

@keyframes loader-1-outter {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-1 .loader-inner {
  position: absolute;
  border: 4px solid #00dada;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 2s cubic-bezier(.42, .61, .58, .41) infinite;
  animation: loader-1-inner 2s cubic-bezier(.42, .61, .58, .41) infinite;
}

@keyframes loader-1-inner {
  0% {
    transform: rotate(0deg);
}

100% {
    transform: rotate(-360deg);
}
}

.loader-center{
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 215px);
  overflow: auto;
  background: #0C0E1D;
  z-index: 9;
  position: relative;
}