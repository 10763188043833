.signin-content-details .user-auth-title-comman {
    color: #fff;
    font-family: 'nunito-bold', sans-serif;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
}

.signin-content-details .user-auth-details-comman {
    color: #fff;
    font-size: 13px;
    line-height: 19px;
    font-family: 'nunito-light', sans-serif;
    margin-bottom: 30px;
}

.user-login-password {
    color: #fff;

}

.mb-30px-form .pass_position {
    margin-left: 0px;
}

.mb-30px-form .icon_end_icon {
    position: absolute;
    right: 16px;
    color: #6A7EB7
}

.deposit_network {
    color: #6A7EB7;
    background: #171B37;
    margin-bottom: 20px;
}

.form-control-details-auth .custom-auth-user-control input {
    background: #171B37;
    color: #6A7EB7;
    font-size: 14px;
    line-height: 19px;
    padding: 10px 15px 9.88px;
    /* border: 0px; */
    border-radius: 8px !important;
}

.form-control-details-auth .custom-auth-user-control input::placeholder,
.icon-position-rel .textarea-content-comman::placeholder {
    color: #6A7EB7 !important;
    font-size: 14px;
    opacity: 1;
    line-height: 19px;
    font-family: 'nunito-regular', sans-serif;
}



.icon-position-rel .formcontrol_login {

    width: 260px;
    color: white;
    margin-top: 20px;

}

.form-control-details-auth .custom-auth-user-control fieldset {
    border: 0px;
}

.login-btn-list-orlist .or-title-list {
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    font-family: 'nunito-light', sans-serif;
    color: #789CFF;
    margin: 10px 0px;
}

.club-register-link .club-link-redirect a,
.club-register-link .club-link-redirect a:hover {
    color: #789CFF;
    font-family: 'nunito-semibold', sans-serif;
    text-decoration: none;
    margin: 0px 3px;
}

.club-register-link .club-link-redirect {
    color: #789CFF;
    font-family: 'nunito-light', sans-serif;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
}

.club-register-link {
    margin-top: 20px;
}

.icon-position-rel {
    position: relative;
}

.icon-pos-top-control {
    position: absolute;
    top: 0;
    left: 15px;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
}

.icon-pos-top-control .mail-icon-main {
    width: 12px;
    height: 12px;
    object-fit: contain;
}

.otp-flex-main-user {
    display: flex;
    align-items: center;
    margin: 0px -6.5px;
}

.otp-input input {
    color: white !important
}

.custom-auth-user-control input {
    color: white !important;
    -webkit-text-fill-color: #fff !important;
}

.profile-mobile-no-color input {
    border: 0px;
}

.otp-flex-main-user .form-control-details-auth {
    padding: 0px 6.5px;
}

.resend-pass-right {
    margin-bottom: 30px;
    text-align: end;
    margin-top: 10px;
}

.resend-pass-right .redirect-resend {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 13px;
    line-height: 18px;
    font-family: 'nunito-light', sans-serif;
}

.password-field-details .MuiOutlinedInput-root {
    padding-right: 0px;
}

.password-field-details .pass_position {
    position: absolute;
    right: 13px;
}

.form-control-details-auth.password-field-details .custom-auth-user-control input {
    padding-right: 60px;
}


.comman-details-auth-user .user-login-password {
    color: #fff;
    font-family: 'nunito-regular', sans-serif;
    margin-bottom: 11px;
    font-size: 14px;
    line-height: 19px;
}

.set-password-text-color {
    color: #fff !important;
}

.otp-custom-details .otp-input input {
    background: #171B37;
    color: #6A7EB7;
    font-size: 14px;
    line-height: 19px;
    border-radius: 8px;
    border: 0px;
}

.otp-custom-details .otp-input input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.user-header-profile {
    display: flex;
    align-items: center;
}

.signin-drop-details .sign-in-inner-form {
    background: #171b37;
    width: 100%;
    border-radius: 8px;
}

.signin-drop-details .sign-in-inner-form .MuiSelect-select {
    color: #fff;
    font-size: 14px;
    line-height: 19px;
    padding: 10px 15px 9.88px;
}

.MuiMenu-list {
    background: #171b37;
    border: 0px;
    font-size: 14px;
}

.MuiMenu-paper {
    background-color: transparent !important;
}

.MuiMenu-list .currency-select-menu {
    color: #fff !important;
    font-size: 14px;
    line-height: 19px;
    font-family: 'nunito-regular', sans-serif;
}

.signin-drop-details .sign-in-inner-form .MuiSelect-icon {
    color: #fff;
}

.profile_fullName {
    color: #789CFF;
}

.withdrawal-details-main .formcontrol_login {
    width: 100%;
}

.withdrawal-details-main .formcontrol_login .MuiSelect-select {
    background: #171B37;
    color: #f6f9ff;
    font-size: 14px;
    line-height: 19px;
    padding: 10px 15px 9.88px;
    font-family: 'nunito-regular', sans-serif;
    border: 0px;
    border-radius: 8px;
}

.withdrawal-details-main .formcontrol_login svg path {
    color: #fff;
}

.modal-comman-inner-style:focus-visible {
    outline: none;
}

.network-li-details {
    color: #fff;
    font-size: 14px;
    font-family: 'nunito-regular', sans-serif;
}

.sign-in-back-btn .back-to-login-details {
    text-align: center;
    width: 100%;
    display: inline-block;
    color: #789CFF;
    text-decoration: none;
    font-family: 'nunito-regular', sans-serif;
    font-size: 15px;
    line-height: 17px;
    margin: 15px 0px 0px 0px;
}


.comman-details-auth-user .forgot-password {
    text-align: end;
    margin-bottom: 30px;
    margin-top: -20px;
}


#menu-currencyValue .MuiBackdrop-root+div+.MuiMenu-paper {
    height: 170px;
}


/* width */
#menu-currencyValue .MuiBackdrop-root+div+.MuiMenu-paper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
}

/* Track */
#menu-currencyValue .MuiBackdrop-root+div+.MuiMenu-paper::-webkit-scrollbar-track {
    background: #5555557a;
    border-radius: 10px;
}

/* Handle */
#menu-currencyValue .MuiBackdrop-root+div+.MuiMenu-paper::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
}

/* Handle on hover */
#menu-currencyValue .MuiBackdrop-root+div+.MuiMenu-paper::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
}

.reset--otp-main p {

    color: white;
    padding-top: 13px;
    padding-bottom: 13px;
}