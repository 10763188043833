@media screen and (min-width: 1200px) and (max-width: 1299px) {}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .main-card-dash-details .main-list-card-details li {
        padding: 0px 10px 20px 10px;
        width: calc(100% / 3);
    }
}

/* phone and tablets */
@media screen and (max-width: 991px) {
    .mini-card-main {
           
            flex-wrap: wrap;
          
        }
    .pl-none {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .main-header {
        padding-right: 15px;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, .2);
        bottom: 0;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        z-index: 999;
    }

    .sidebar-none {
        left: 0px;
    }

    .res-menu {
        display: block;
    }

    .rightbox {
        max-width: 100%;
        border-radius: 0px 0px 0px 0px;
    }

    .logo-set img {
        width: 300px;
    }

    .background-hight {
        height: 100%;
    }

    .set-filter-main {
        margin-top: 15px;
    }

    /* / start raponsive 13/6 / */
    .select-modal-box {
        max-width: 95%;
    }

    /* / end raponsive 13/6 / */

    .background-hight {
        height: 100%;
    }

    .set-filter-main {
        margin-top: 15px;
    }

    .chart-box {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%;
    }

    .mini-card {
        margin-bottom: 15px;
    }

    .chart-box:last-child {
        margin-bottom: 0;
    }


    .sidebar-main.active {
        left: -230px;
    }
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .res-set-search {
        margin-top: 30px;
    }

    .ipad-flex-small-content .res-set-search{
        margin-top: 0px;
    }

    .main-card-dash-details .main-list-card-details li {
        padding: 0px 10px 20px 10px;
        width: calc(100% / 3);
    }

    .cattable-devider table thead tr th:first-child {
        width: 3%;
    }

    .cattable-devider table thead tr th:nth-child(2) {
        width: 25%;
    }

    .cattable-devider table thead tr th:last-child {
        width: 6%;
    }

    .cattable-port table thead tr th:last-child {
        width: 6%;
    }

    .cattable-devider table tbody tr td:nth-child(2) {
        width: 36%;
    }

    .cattable-devider table tbody tr td:first-child {
        width: 4%;
    }

    .mini-card {
        min-width: 228px;
    }

    .mini-card:nth-child(3n-3) {
        margin-right: 0;
    }

    .main-header {
        padding-left: 15px;
    }

    .admin-panel-content {
        padding: 20px 15px 20px 15px;
    }

    .main-box-dash {
        max-width: 100%;
        width: calc(100% / 2 - 15px);
    }

    .supply-table {
        min-width: 1329px;
    }

    .tanker-table {
        min-width: 687px;
    }

    .invoice-table {
        min-width: 1380px;
    }

    .res-menu-icon svg {
        display: block;
    }

    .res-close-btn {
        display: flex !important;
        margin-left: auto !important;
        margin-top: 20px !important;
        width: 20px;
        height: 20px;
        padding: 0 !important;
    }

    .res-close-btn img {
        width: 16px;
        height: 16px;
        object-fit: cover;
    }

    .header-ul-main {
        display: none;
        display: block;
        background: #003d90;
        position: fixed;
        top: 0;
        width: 250px;
        left: 0;
        bottom: 0;
        z-index: 99;
        transition: 0.5s all;
    }

    .header-ul-main .header-ul {
        display: block;
        padding-top: 50px;
    }

    .header-ul li {
        margin-bottom: 10px;
        padding: 7px 15px;
    }

    /* / start raponsive 13/6 / */
    .select-img-box {

        justify-content: center;
    }

    .filter-close-btn img {
        height: 28px;
        width: 23px;
    }

    /* / end raponsive 13/6 / */



    /* .chart-box {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 15px;
    }

    .mini-card {
        min-width: 232px;
        margin-bottom: 15px;
    }
    .chart-box:last-child {
        margin-bottom: 0;
    }
    .cattable-devider table thead tr th:last-child {
        width: 9%;
    }
    .cattable-port table thead tr th:last-child{
        width: 7%;
    }
    .cattable-port table tbody tr td:last-child {
        width: 9%;
    } */
}

/* phone */
@media (max-width: 767px) {
    .ipad-flex-small-content .mt-0px-res{
        margin-top: 0px;
    }
    .admin-panel-content {
        padding: 20px 15px 20px 15px;
    }
    .choose-your-company-screen {
        padding: 30px;
    }

    .chart-box {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .res-barge-table {
        min-width: 716px;
    }

    .cattable-devider table thead tr th:last-child {
        width: 15%;
    }

    .cattable-devider table thead tr th:nth-child(2) {
        width: 23%;
    }

    .cattable-devider table thead tr th:first-child {
        width: 7%;
    }

    .cattable-port table thead tr th:last-child {
        width: 18%;
    }

    .cattable-port table thead tr th:nth-child(2) {
        width: 31%;
    }

    .cattable-port table thead tr th:first-child {
        width: 10%;
    }

    .header-ul-main {
        display: none;
        display: block;
        background: #003d90;
        position: fixed;
        top: 0;
        width: 200px;
        left: 0;
        bottom: 0;
        z-index: 99;
        transition: 0.5s all;
    }

    .header-ul-main .header-ul {
        display: block;
        padding-top: 50px;
    }

    .header-ul li {
        margin-bottom: 10px;
        padding: 7px 15px;
    }

    .main-box-dash {
        max-width: 100%;
        width: calc(100% / 1);
    }

    .change-password-main {
        min-width: 340px;
    }

    .profile-card-main {
        width: 340px;
    }

    .res-title-header-flex {
        flex-direction: column;
        align-items: start !important;
    }

    .res-set-search {
        flex-wrap: wrap;
        margin-top: 30px;
    }


    .supply-table {
        min-width: 1329px;
    }

    .filter-main .MuiPaper-elevation {
        min-width: 300px;
    }

    .tanker-table {
        min-width: 687px;
    }

    .invoice-table {
        min-width: 1380px;
    }

    .res-menu-icon svg {
        display: block;
    }

    .res-close-btn {
        display: flex !important;
        margin-left: auto !important;
        margin-top: 20px !important;
        width: 20px;
        height: 20px;
        padding: 0 !important;
    }

    .res-close-btn img {
        width: 16px;
        height: 16px;
        object-fit: cover;

    }

    /* / start raponsive 13/6 / */
    .select-modal-box {
        max-width: 95% !important;
    }

    .modal-select-img-box {
        justify-content: center;
    }

    .select-modal-img-box {
        padding: 25px 25px;
    }

    .res-profile-content {
        min-height: calc(100vh - 83px) !important;
        margin-top: 83px !important;
    }

    .select-card-main {
        padding: 30px;
    }

    .dashboard-text p,
    .title-main .select-page-title {
        font-size: 20px;
        line-height: 27px;
    }

    .mb-41 {
        margin-bottom: 20px;
    }

    .select-img-box {
        justify-content: center;
    }

    .compny-img {
        padding: 18.5px;
    }

    .compny-img-inner {
        width: 158px;
        height: 128px;
    }

    /* / end raponsive 13/6 / */



    .mini-card {
        width: calc(100% / 2);
        flex-basis: calc(100% / 2);
        max-width: calc(100% / 2);
    }

    .mini-card-img {
        background-color: #FCCAF2;
        border-radius: 4px;
        height: 33px;
        width: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
    }

    .card-right-content .card-total {
        font-size: 10px;
        line-height: 13px;
        margin-bottom: 0;
    }

    .card-right-content .card-price {
        font-size: 14px;
        line-height: 20px;
    }

    .mini-card-img img {
        width: 16px;
        height: 16px;
    }

    .mini-card:nth-child(2n-2) {
        margin-right: 0;
    }

    .chart-header .chart-title {
        font-size: 13px;
        line-height: 19px;
    }

    .main-header {
        padding-left: 15px;
    }
    .filter-close-btn img {
        height: 28px;
        width: 23px;
    }

    .res-blue-button {
        display: flex;
        width: 100%;
        margin: 10px 0px 10px auto;
    }

}