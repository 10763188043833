@media screen and (min-width: 1600px) and (max-width: 1919px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {

}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

@media screen and (min-width: 992px) and (max-width: 1279px) {
    /* .row-numberbetting {
        height: calc(100% - 600px);
    } */
    .bg-number-betting {
        height: calc(100% + -12px);
    }
    .col7-number {
        width: 65%;
        max-width: 65%;
        flex: 0 0 65%;
    }
    .col5-number {
        width: 35%;
        max-width: 35%;
        flex: 0 0 35%;
    }
    
}

@media screen and (max-width: 991px) {
    .ball-selected-details .ball {
        margin-bottom: 0px;
    }
    .number-betting-records .game-records-details{
        padding: 0px 14px;
    }
    .game-records-details.pb-30px{
        padding: 0px 14px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .row-numberbetting{
        height: unset;
    }
  
    .col7-number{
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .bet-coin-num-list .flex-bet-list-ul .number-list-details {
        width: calc(100% / 10);
    }

    .col5-number{
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }
    
}

@media screen and (max-width: 767px) {
    .game-records-details.pb-30px {
        padding: 0px 13px;
    }
    .number-betting-pd .pb-30px {
        padding-bottom: 50px;
    }
 
    .row-numberbetting{
        height: unset;
    }
    .col7-number{
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .col5-number{
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .bg-number-betting {
        height: initial;
        margin: 10px 10px 20px 10px;
    }

    .number-bet-scroll {
        height: 61vh;
        overflow: auto;
    }

    .center-justify-ball{
        max-width: calc(100vw - 50px);
    overflow: auto;
    justify-content: start;
    height: calc(100vh - 140px);
    position: initial;
    }

    .flex-content .ball-container:nth-child(even) {
        margin-left: 0px;
    }

    .ball {
        /* margin-bottom: 15px; */
        margin: 0px 10px 15px 10px;
    }

    .flex-content{
        margin-left: 0px;
        justify-content: initial;
        height: 100%;
    }

    .ball-container {
        height: initial;
    }

    @keyframes center-animation {
        0% {
          transform: scale(0);
        }
      
        20% {
          transform: scale(7);
        }
      
        40% {
          transform: scale(7);
        }
      
        60% {
          transform: scale(7);
        }
      
        100% {
          transform: scale(0);
        }
      }

}

@media screen and (max-width: 361px){
    .ball {
        /* margin-bottom: 15px; */
        margin: 0px 10px 15px 10px;
    }
    .user-inner-content-scroll .number-betting-pd {
        height: calc(100vh - 121px);
    }
}



@media screen and (min-width: 280px) and (max-width: 320px) {
    
    .bet-coin-num-list .flex-bet-list-ul .number-list-details {
        width: calc(100% / 3);
    }
    .ball {
        height: 33px;
        /* margin: 0px 4px 15px 4px; */
        width: 33px;
        margin: 0px 4.5px 12px 4.5px;
    }
    .bet-coin-num-list .flex-bet-list-ul .number-list-details .bg-num-bet-conetnt{
        width: 100%;
    }
    .ball span {
        font-size: 9px;
        width: 20px;
        height: 20px;
    }

    @keyframes center-animation {
        0% {
          transform: scale(0);
        }
      
        20% {
          transform: scale(6);
        }
      
        40% {
          transform: scale(6);
        }
      
        60% {
          transform: scale(6);
        }
      
        100% {
          transform: scale(0);
        }
      }

}