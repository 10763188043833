@media screen and (min-width: 1600px) and (max-width: 1919px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

@media screen and (min-width: 992px) and (max-width: 1279px) {
    .user-dashboard-details {
        flex-wrap: wrap;
    }

    .user-dashboard-details .dashborad-total-details {
        width: calc(100% / 3);
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 991px) {
    .user-dashboard-details {
        flex-wrap: wrap;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .user-dashboard-details .dashborad-total-details {
        width: calc(100% / 3);
        margin-bottom: 15px;
    }

    .dashborad-total-details .dashboard-title-user {
        font-size: 16px;
        margin-bottom: 11px;
    }
}

@media (max-width: 767px) {
    .user-dashboard-details .dashborad-total-details {
        width: calc(100% / 1);
        margin-bottom: 15px;

    }

}

@media screen and (min-width: 280px) and (max-width: 319px) {}