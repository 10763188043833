.admin-change-password .MuiInputBase-input {
    padding: 7.45px 10px !important;
}

.admin-change-password .MuiFilledInput-underline{
    height: 35px;
}

.admin-change-password{
    margin-bottom: 15px;
}

.admin-change-password .input-label{
    color: #fff;
    margin-bottom: 2px;
}

.ml-sumbit-change{
    margin-left: 10px;
}


.change-pass-details{
    height: calc(100vh - 130px);
}