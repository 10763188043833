

@media screen and (min-width: 1600px) and (max-width: 1919px) {}

@media screen and (min-width: 1366px) and (max-width: 1599px) {}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

@media screen and (min-width: 992px) and (max-width: 1279px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (min-width: 768px) and (max-width: 991px) {
}

@media (max-width: 767px) {
    .query-form-details-content .query-card-main-content {
        padding: 20px 15px;
    }

    
}