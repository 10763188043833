@media screen and (min-width: 1200px) and (max-width: 1299px) {}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {}

/* phone and tablets */
@media screen and (max-width: 991px) {
    /* .profile-content {
        height: initial;
        min-height: initial;
    } */
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {

   
}

/* phone */
@media (max-width: 767px) {
    .card-main{
        padding: 30px 15px;
    }
}