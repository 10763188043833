
.box-login-text1{
    font-family: 'inter-regular', sans-serif;
    line-height: 15px;
    margin: 20px 170px -10px 0px;
}

.bluebox-text1{
    color: #fff;

}

.mb-20px-details {
    margin-bottom: 20px;
}