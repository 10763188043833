/* sidebar css start */
.user-sidebar-main-flex .main-user-logo-content {
    padding: 30px 20px;
    text-align: center;
}

.user-sidebar-main-flex {
    background: #10131F 0% 0% no-repeat padding-box;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.user-sidebar-main-flex::after {
    content: '';
    width: 100%;
    background: linear-gradient(88deg, transparent 38%, rgb(81 16 162 / 50%));
    height: 285px;
    position: absolute;
    left: 0;
    top: 14%;
    z-index: -1;
    filter: blur(81px);
}

.user-sidebar-main-flex::before {
    content: '';
    width: 100%;
    background: linear-gradient(88deg, transparent 38%, rgb(98 99 251 / 50%));
    height: 285px;
    position: absolute;
    left: 0;
    top: 37%;
    z-index: -1;
    filter: blur(81px);
}

.active-user-icon {
    display: none;
}

.active-user-icon {
    display: none;
}

.list-user-sidebar {
    padding: 0px;
}

.list-user-sidebar .listitem-user-inner {
    padding: 11px 15px 10px 15px;
    border: 1px solid transparent;
}

.list-user-sidebar .listitem-user-inner.active {
    background: transparent linear-gradient(90deg, #2e60eb 0%, #5F62FB00 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px 0px 0px 20px;
    border: 1px solid #04b6bc;
    border-right: 0px;
}

.user-flex-menu {
    display: flex;
    align-items: center;
}

.user-icon-list {
    display: flex;
}

.list-user-sidebar .listitem-user-inner.active .none-active-user-icon {
    display: none;
}

.list-user-sidebar .listitem-user-inner.active .active-user-icon {
    display: block;
}

.list-user-sidebar .listitem-user-inner img {
    margin-right: 10px;
    height: 16px;
    object-fit: contain;
}

.inner-user-redirect-page {
    text-decoration: none;
}

.user-flex-menu .sidebar-user-title {
    color: #8D99CB;
    font-size: 15px;
    line-height: 20px;
    font-family: 'nunito-regular', sans-serif;
}

.list-user-sidebar .listitem-user-inner.active .user-flex-menu .sidebar-user-title {
    color: #fff;
}

.owl-bg-sidebar {
    background: #27334f 0% 0% no-repeat padding-box;
    border-radius: 8px;
    backdrop-filter: blur(44px);
    -webkit-backdrop-filter: blur(44px);
    position: relative;
    overflow: hidden;
}

.owl-bg-sidebar::after {
    content: '';
    background-image: radial-gradient(#00F0EE, #57bffe00);
    height: 92px;
    width: 92px;
    position: absolute;
    right: 0;
    bottom: 9px;
    z-index: -1;
    filter: blur(51px);
}

.owl-bg-sidebar::before {
    content: '';
    background-image: radial-gradient(#466EFC, #57bffe00);
    height: 116px;
    width: 116px;
    position: absolute;
    left: 0;
    bottom: 14px;
    z-index: -1;
    filter: blur(51px);
}

.user-sidebar-owl-carousel {
    padding: 9px 20px 35px 20px;
}

.sidebar-card-img-inner {
    height: 114px;
    max-width: 160px;
    margin: auto;
}

.owl-bg-sidebar .pd-owl-sidebar {
    padding: 67px 15px 12px 15px;
}

.img-card-sidebar {
    position: absolute;
    z-index: 9;
    top: -58px;
    left: 0;
    right: 0;
}

.slider-bg-details .community-title-slider {
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    font-family: 'nunito-semibold', sans-serif;
}

.slider-bg-details .community-details-slider {
    font-size: 8px;
    line-height: 11px;
    font-family: 'nunito-regular', sans-serif;
    color: #fff;
    margin-top: 4px;
}

.user-sidebar-owl-carousel .owl-stage-outer {
    padding-top: 58px;
}

.user-sidebar-owl-carousel .owl-slider-main-content .owl-dots .owl-dot span {
    background: rgb(46 96 235 / 30%);
    height: 5px;
    width: 5px;
    margin: 0px 3px;
}

/* .user-sidebar-owl-carousel .owl-slider-main-content .owl-dots .owl-dot.active span {
    background: #2E60EB;
} */

.user-sidebar-owl-carousel .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 0px;
    height: 0px;
}

.list-user-details-main {
    padding: 0px 0px 0px 15px;
    height: calc(100vh - 415px);
    overflow: auto;
}

/* sidebar css end */

/* header css start */

.bg-user-details {
    background: #0C0E1D;
    padding: 23px 45px 21px 29px;
    border-bottom: 1px solid #2B2A5D;
}

.search-input-content .search-input-control input {
    background: #171B37;
    border: 1px solid transparent;
    color: #475887;
    padding: 8px 20px 9px 41px;
    border-radius: 30px !important;
    font-family: 'nunito-regular', sans-serif;
    font-size: 14px;
    line-height: 19px;
}

.search-input-content .search-input-control input::placeholder {
    color: #475887;
}

.search-input-content .search-input-control fieldset {
    border: transparent;
}

.search-input-content {
    position: relative;
}

.search-input-content .headersearch {
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.wallet-bg-main {
    background: #2E60EB 0% 0% no-repeat padding-box;
    border-radius: 18px;
}

.wallet-bg-main .wallet-pd-content {
    display: flex;
    align-items: center;
}

.user-flex-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wallet-bg-main {
    background: #2E60EB 0% 0% no-repeat padding-box;
    border-radius: 30px;
}

.wallet-bg-main .wallet-pd-content {
    display: flex;
    align-items: center;
    /* padding: 10px 4px 10px 15px; */
    padding: 12px 33px 12px 33px;
}

.wallet-bg-main .btn-plus-circle-file {
    padding: 0px;
    min-width: initial;
}

.wallet-bg-main .btn-plus-circle-file .pluscirclefill-main {
    height: 28px;
    width: 28px;
    border-radius: 50%;
}

.wallet-bg-main .wallet-number {
    padding: 0px 0px 0px 10.2px;
    font-size: 14px;
    font-family: 'nunito-regular', sans-serif;
    color: #FFFFFF;
}

.header-right-top-content .comman-header-mrl {
    padding: 0px 10px;
}

.wallet-main {
    width: 21.84px;
    height: 15.6px;
    object-fit: contain;
}

.notification-main-content .notification-btn,
.notification-main-content .notification-btn:hover {
    background: #fff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    min-width: initial;
}

.header-right-top-content {
    display: flex;
    align-items: center;
    margin: 0px -10px;
}

.notification-main-content .notification-btn .bell-img-content {
    width: 14.15px;
    height: 16.5px;
}

.connect-wallet-btn-main .connect-btn-main,
.connect-wallet-btn-main .connect-btn-main:hover {
    background: #2E60EB;
    color: #fff;
    padding: 10px 33px 11px 33px;
    border-radius: 30px;
    font-size: 14px;
    text-transform: capitalize;
    font-family: 'nunito-regular', sans-serif;
    line-height: 19px;
}

.main-top-user-header {
    position: fixed;
    left: 238px;
    right: 0;
    z-index: 19;
    /* z-index: 99; */
}

/* header css end */


.list-user-details-main::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 10px;
}

/* Track */
.list-user-details-main::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
.list-user-details-main::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
}

/* Handle on hover */
.list-user-details-main::-webkit-scrollbar-thumb:hover {
    background: #f1f1f1;
    border-radius: 10px;
}

/* modaldeposit */

.title-deposit-transaction .inner-title-transaction {
    color: #fff;
    font-family: 'nunito-regular', sans-serif;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
}

/* conflict */
.user-header-profile {
    display: flex;
    align-items: center;
}

.set-btn-main-list .btn-set-password-details,
.set-btn-main-list .btn-set-password-details:hover {
    background: #2e60eb;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-family: "nunito-regular", sans-serif;
    font-size: 14px;
    line-height: 19px;
    min-height: 34px;
    text-transform: capitalize;
    width: 100%;
}

/* conflict */

.menu-burgur-header,
.connect-icon-details {
    display: none;
}

.drop-header-menu .drop-header-menuitem.mobile-menu-show {
    display: none;
}

.mobile-drop-menu-main,
.mobile-btn-hidden {
    display: block;
}

.connect-icon-details {
    height: 20px;
    width: 20px;
}

.mobile-text-hidden,
.wallet-num-list {
    display: block;
}

.balance-title-details .balances-main-content {
    color: #fff;
    font-family: 'nunito-bold', sans-serif;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 6px;
}

.balance-mobile-value {
    padding: 25px 0px 0px 0px;
}
/* 
.balance-mobile-value .wallet-bg-main .wallet-pd-content {
    justify-content: space-between;
} */

.balance-mobile-value .wallet-bg-main .wallet-number {
    font-size: 20px;
}

.balance-mobile-value .wallet-main {
    width: 30.84px;
    height: 19.6px;
}

/*  */

.admin-sidebar-listitem .expandless-icon,
.admin-sidebar-listitem .expandmore-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.admin-sidebar-submenulist .admin-sidebar-link {
    margin-left: 10px;
}

.admin-sidebar-submenulist .admin-sidebar-listitem:first-child .admin-sidebar-link {
    margin-top: 20px;
}

.overlay-section, .chat-overlay-section,
.desktop-hidden {
    display: none !important;
}

.footer-comman-layout {
    /* padding: 15px 30px;
    background: #0C0E1D;
    z-index: 9;
    position: relative;
    border-top: 1px solid #2B2A5D; */
    padding: 15px 30px;
    background: #0C0E1D;
    /* z-index: 9; */
    /* position: relative; */
    border-top: 1px solid #2B2A5D;
    position: fixed;
    right: 0;
    left: 238px;
    bottom: 0;
    z-index: 9;
}

.footer-comman-layout .user-footer-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right-cms-content ul {
    display: flex;
    align-items: center;
    padding: 0px;
    justify-content: end;
}

.right-cms-content ul li {
    font-size: 14px;
    line-height: 19px;
    font-family: 'nunito-regular', sans-serif;
    padding: 0px 10px;
    width: inherit;
}

.right-cms-content ul li a {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.copyright-title-details .copy-details-footer {
    font-size: 14px;
    color: #fff;
    font-family: 'nunito-regular', sans-serif;
    line-height: 19px;
}

.copyright-title-details .copy-details-footer a {
    font-family: 'nunito-bold', sans-serif;
    text-decoration: none;
    color: #fff;
}

.MuiTablePagination-actions .MuiIconButton-root {
    color: rgb(141 154 201) !important;
}

.p-0-inner-tab {
    padding: 0px;
}

.sign-out-color {
    color: #DD3535 !important;
}

.header-user-dropdown-menu ul {
    min-width: 240px;
}

.header-user-dropdown-menu .MuiPaper-rounded {
    margin-left: -17px;
}

.header-user-dropdown-menu .header-profile-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.deposit-tab-border {
    border-bottom: 2x !important;
    border-color: #fff !important;
}
