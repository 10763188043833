.chat-drawer .MuiDrawer-paper {
  transition: none !important;
  height: calc(100vh - 143px);
  top: 93px;
  width: 250px;
  overflow: hidden;
  background: #0c0e1d;
  box-shadow: none;
}

.chat-details-inner {
  background: #0c0e1d;
  z-index: 999;
  position: relative;
}


.chatbox {
  height: calc(100vh - 228px);
  padding: 5px;
  overflow: auto;
}

.chatbox::-webkit-scrollbar {
  width: 5px;
}

.chatbox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.chatbox::-webkit-scrollbar-thumb {
  background: #5356fb;
  border-radius: 10px;
}

.chat-drawer .MuiModal-backdrop {
  display: none;
}

.chat-drawer {
  position: unset !important;
}


/* admin */

.chat.chat-right-msg + .chat-right-msg,
.chat.chat-left-msg + .chat-left-msg {
  margin-top: 0;
}
.chat-heading-text {
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid #2b2a5d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-chat-cancel .close-icons {
  height: 20px;
  width: 20px;
}

.main-chat-back  .add-chat-cancel {
  min-width: max-content;
  padding: 0px;
  align-items: end;
  display: none;
}

.chat-heading-text .chat-heading-main-text {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  margin-top: 4px;
  font-weight: 800;
  display: flex;
  gap: 5px;
}

.chat-container {
  border-left: 1px solid #2b2a5d;
}

.chat-top-border {
  border-top: 1px solid #2b2a5d;
  position: relative;
}

.chat-icon-details {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-design-div.with-border.chat-top-border input {
  font-size: 14px;
  padding-right: 60px !important;
  /* new */
  background-color: #212529;
  /* border: 1px #80808087; */
  border-radius: 0px !important;

}

.send-icon-box svg {
  color: #fff;
  height: 17px;
}

.camera-icon-box svg {
  color: #2e60eb;
  height: 17px;
}

.user-icons-chat .chat-icon-list {
  height: 25px;
  width: 25px;
  object-fit: cover;
  border-radius: 50%;
}

.user-icons-chat {
  display: flex;
  align-items: center;
  gap: 6px;
}

.right-user-chat {
  max-width: 220px;
  margin-left: auto;
}

.left-user-chat {
  /* max-width: 220px; */
  max-width: 90%;
  margin-left: unset;
  margin-right: auto;
}

.common-chat-content-details {
  margin-bottom: 8px;
}

.left-user-chat .chat-user-flex {
  justify-content: start;
}

.chat-user-flex {
  display: flex;
  align-items: start;
  gap: 6px;
  /* background-color: #80808087; */
  background-color: #212529;
  border: 1px #80808087;
  border-radius: 5px;
  padding: 12px;
  line-height: 2;

}

.user-chat-input-main .user-chat-input {
  background-color: "#212529" !important;
}

.chat-right {
  flex-direction: row-reverse;
  justify-content: end;
  text-align: end;
  max-width: 90%;
    margin-left: auto;
}

.chat-right .user-chat-timing {
  padding-right: 0 !important;
}

.description-chat-live .user-chat-timing {
  font-size: 10px;
  line-height: 12px;
  padding-right: 5px;
  color: #ccccce;
  font-family: 'inter-regular';
}

.user-chat-msg {
  font-size: 15px;
}

.description-chat-live .user-naming {
  /* font-size: 10px; */
  font-size: 11px;
  line-height: 12px;
  /* color: #9eacf1; */
  color: white;
  padding-right: 5px;
  font-family: 'inter-regular';
  /* font-family: "revert-layer"; */
  font-weight: 800;
}

.description-chat-live {
  font-size: 10px;
  color: white;
  font-family: 'inter-regular';
  width: 174px;
}

.chat-upload-img {
  display: none
}

.chat-images img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.chat-betting-main .chat-betting {
  min-width: max-content;
  padding: 5px 10px;
  /* display: flex; */
  margin-left: 0px;
  align-items: center;
}